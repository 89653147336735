<template>
  <div class="container">
    <div class="card text-center">
      <div class="card-header">
        <ul class="nav nav-tabs card-header-tabs">
          <li class="nav-item" v-for="(groupDef, key) in groups" :key="key">
            <a
              :class="'nav-link ' + (selectedGroup == key ? 'active' : '')"
              href="#"
              @click="selectGroup(key)"
              >{{ groupDef.name }}</a
            >
          </li>
        </ul>
      </div>
      <div class="card-body">
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Filename</th>
              <th scope="col">Download</th>
              <th scope="col">Uploaded at</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="file in filteredFiles" :key="file.id">
              <th scope="row">{{file.name}}</th>
              <td>
                <a :href="file.url">Download</a>
              <a href="/api/cms?name=meeting_room.png" target="_blank">test</a></td>
              <td>{{file.uploaded_at}}</td>
              <td>
                <button type="button" class="btn btn-secondary btn-sm">Save</button>
                <button type="button" class="btn btn-secondary btn-sm">Delete</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  metaInfo() {
    return { title: this.$t("file_management") };
  },

  data: () => ({
    files: {
        'fake': { id: 'fake', group: 'web', name: 'fake.png', url: './asset/web/fake.png', uploaded_at: '2021/05/25'},
        'fake2': { id: 'fake2', group: 'investor', name: 'fake2.png', url: './asset/investor/fake.png', uploaded_at: '2021/05/28'}
    },
    groups: {
      investor: {
        name: "Investor's information",
      },
      web: {
        name: "Website materials",
      },
    },

    selectedGroup: "investor",
  }),

  computed: {
      filteredFiles: function(){
          console.log(this.files)
          return Object.values(this.files).filter(f => f.group === this.selectedGroup)
      },
  },

  methods: {
    async getFiles() {
      // Submit the form.
      const { data } = await this.form.post("/api/files");
      files = data;
    },
    selectGroup(groupKey){
        this.selectedGroup = groupKey
    }
  },
};
</script>
